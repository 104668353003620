import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/analytics";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const firebaseConfig = {
  apiKey: "AIzaSyDEEJk3Q3jCJmlTYlG_mJvxc5Qwg0NpbH0",
  authDomain: "first-fortress-307013.firebaseapp.com",
  projectId: "first-fortress-307013",
  storageBucket: "first-fortress-307013.appspot.com",
  messagingSenderId: "626411393893",
  appId: "1:626411393893:web:8f7f9aa37b80a0ae1c078c",
  measurementId: "G-CVS20NTFLV",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

const auth = firebase.auth();
auth.useDeviceLanguage();
let functions;

// Always run functions/emulate before developing.
if (process.env.NODE_ENV !== "production") {
  functions = firebase.app().functions("europe-west3");
  functions.useEmulator("localhost", 5001);
  db.useEmulator("localhost", 8081);
  auth.useEmulator("http://localhost:9099");
} else {
  firebase.analytics();
  functions = firebase.app().functions("europe-west3");
}

// User functions
const listUsers = functions.httpsCallable("users-listUsers");
const deleteUser = functions.httpsCallable("users-deleteUser");
const createUser = functions.httpsCallable("users-createUser");
const updateUser = functions.httpsCallable("users-updateUser");
const registerUser = functions.httpsCallable("users-registerUser");

export { listUsers, deleteUser, createUser, updateUser, registerUser };

// Gamesession functions
const getSessionStats = functions.httpsCallable("gameSessions-getSessionStats");
const deleteSession = functions.httpsCallable("gameSessions-deleteSession");

export { getSessionStats, deleteSession };

// Track functions
const deleteTrack = functions.httpsCallable("tracks-deleteTrack");

export { deleteTrack };

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore;
export { Timestamp, GeoPoint, auth, db };
