import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "login" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_header = _resolveComponent("el-header")
  const _component_LoginForm = _resolveComponent("LoginForm")
  const _component_el_main = _resolveComponent("el-main")
  const _component_el_container = _resolveComponent("el-container")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, null, {
          default: _withCtx(() => [
            _createVNode("h1", null, _toDisplayString(this.$t("general.title")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => [
                _createVNode(_component_LoginForm, {
                  onLoginError: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showErrorNotification(_ctx.$t('login.error.not_found')))),
                  onLoginSuccess: _ctx.loginUser
                }, null, 8, ["onLoginSuccess"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}