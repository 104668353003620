export default {
  "switch_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt språk:"])},
  "general": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiraittilas upphovsrättsbana"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genom att skapa ett användarkonto kan du skapa egna spel och banor i Kopiraittilas mobilspel Upphovsrättsbanan. Med samma användarnamn kan du logga in i kontrollpanelen där du kan kontrollera spelresultat och hantera dina spel."])},
    "privacypolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dataskyddsbeskrivning"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jo"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nej"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka"])},
    "roles": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administratör"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lärare"])},
      "no_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen roll"])}
    },
    "messages": {
      "error": {
        "something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett fel uppstod."])}
      }
    },
    "actions": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga ut"])}
    },
    "tooltips": {
      "menu": {
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppna menyn"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stäng menyn"])}
      }
    }
  },
  "register": {
    "backend": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Error while creating the user occurred, please contact an administrator for assistance."])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa användarnamn"])},
    "name": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
      "error": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn krävs"])},
        "wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "error": {
      "weak_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenordet är för svagt"])},
      "user_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användaren finns redan"])}
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa"])},
    "to_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])},
    "email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post"])},
      "error": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post krävs"])},
        "wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posten är felformatterad"])}
      }
    },
    "password": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])},
      "error": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord krävs"])},
        "wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenordet är för kort"])}
      }
    },
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glömde du lösenordet?"])},
    "reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa nytt lösenord"])},
    "back_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka till inloggningssidan"])},
    "login_to_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du behöver vara inloggad för att se denna sida"])},
    "error": {
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posten eller lösenordet är fel, försök igen."])},
      "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggning är inte tillåten."])}
    },
    "reset": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruktioner för att återställa lösenordet har skickats till din epost."])}
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])}
  },
  "menu": {
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])},
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spel"])},
    "mygames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egna spel"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banor"])},
    "mylevels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egna banor"])}
  },
  "users": {
    "table": {
      "headers": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
        "in_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I bruk"])},
        "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användarroll"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök e-post"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])}
      },
      "actions": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till användare"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editera"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera"])},
        "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka lösenordsåterställning"])}
      }
    },
    "messages": {
      "error": {
        "removing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel uppstod vid borttagning av användare"])}
      },
      "success": {
        "removing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare borttagen"])}
      },
      "alerts": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill ta bort denna användare?"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du skicka ett lösenordsåterställnings meddelande till användaren?"])}
      }
    },
    "modal": {
      "create": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till användare"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användaren skapades!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapandet av användaren misslyckades! Ett användarkonto med denna epost kanske redan finns."])}
      },
      "update": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera användare"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare uppdaterad"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett fel uppstod vid uppdatering av användaren"])}
      }
    }
  },
  "levels": {
    "track": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bana"])},
      "table": {
        "headers": {
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])}
        }
      },
      "actions": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa statistik"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera"])}
      },
      "alerts": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill ta bort detta spel?"])}
      }
    },
    "table": {
      "headers": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
        "set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frågeserie"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelkod"])},
        "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klass"])},
        "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upphovsmän"])},
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plats"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])}
      },
      "actions": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa spel"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera"])}
      },
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga banor hittade"])},
      "alerts": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill radera denna bana?"])}
      }
    },
    "errors": {
      "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett fel uppstod vid radering av banan"])},
      "deletion_cause_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banan kan inte raderas, eftersom den ännu har aktiva spel"])}
    },
    "success": {
      "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banan är readerat"])}
    }
  },
  "game": {
    "table": {
      "headers": {
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök spel"])}
      }
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spel"])},
    "players": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelare"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök spelare"])},
      "tabs": {
        "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammanfattning"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla spelare"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tid (Medeltal)"])}
      },
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fråga"])},
      "table": {
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
          "answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svar"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tid"])},
          "puzzle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pussle"])}
        }
      },
      "average_time": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tid (Medeltal)"])}
      }
    },
    "questions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frågor"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "errors": {
      "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett problem uppstod vid borttagningen av spelet"])}
    },
    "success": {
      "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelet borttaget"])}
    }
  }
}