import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "../views/Login.vue";
import { nextTick } from "vue";
import { auth } from "@/firebase";
import i18n from "./../i18n";

const routes: Array<RouteRecordRaw> = [
  {
    name: "Main",
    path: "/:locale",
    component: {
      template: "<router-view></router-view>",
    },
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale as string;
      const supported_locales = i18n.global.availableLocales;
      if (!supported_locales.includes(locale)) {
        return next({
          name: "Login",
          query: { redirect: to.path },
          params: { locale: "fi" },
        });
      }

      if (i18n.global.locale.value !== locale) {
        i18n.global.locale.value = locale;
      }
      return next();
    },
    children: [
      {
        path: "",
        name: "Login",
        component: Login,
        meta: {
          title: "Login",
          hideForAuth: true,
        },
      },
      {
        path: "aloita",
        name: "Register",
        component: () =>
          import(/* webpackChunkName: "aloita" */ "../views/Register.vue"),
        meta: {
          title: "Aloita",
          hideForAuth: true,
        },
      },
      {
        path: "reset",
        name: "ResetPassword",
        component: () =>
          import(
            /* webpackChunkName: "resetPassword" */ "../views/ResetPassword.vue"
          ),
        meta: {
          title: "Reset Password",
          hideForAuth: true,
        },
      },

      {
        path: "dashboard",
        name: "Dashboard",
        meta: {
          title: "Dashboard",
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "dashboardIndex" */ "../views/Dashboard/Index.vue"
          ),
        children: [
          {
            path: "games",
            name: "Games",
            meta: {
              title: "Pelit",
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "dashboardLevels" */ "../views/Dashboard/Games.vue"
              ),
          },
          {
            path: "levels",
            name: "Levels",
            meta: {
              title: "Radat",
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "dashboardLevels" */ "../views/Dashboard/Tracks.vue"
              ),
          },
          /*
          {
            path: "level/:id",
            name: "SingleLevel",
            meta: {
              title: "Rata",
              requiresAuth: true,
            },
            component: () =>
              import(
                "../views/Dashboard/SingleLevel.vue"
              ),
          },*/
          {
            path: "level/:id/session/:code",
            name: "Session",
            meta: {
              title: "Peli",
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "dashboardLevel" */ "../views/Dashboard/GameSession.vue"
              ),
          },
          {
            path: "users",
            name: "Users",
            meta: {
              title: "Käyttäjät",
              requiresAuth: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "dashboardUsers" */ "../views/Dashboard/Users.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: (to) => {
      return {
        name: "Login",
        params: {
          locale: to.params.locale ?? i18n.global.fallbackLocale.value,
        },
      };
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.beforeEach(async (to, _from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const hideForAuth = to.matched.some((x) => x.meta.hideForAuth);

  if (requiresAuth && !auth.currentUser) {
    next({
      name: "Login",
      params: {
        locale:
          to.params.locale ??
          i18n.global.locale.value ??
          i18n.global.fallbackLocale.value,
      },
      query: { redirect: to.path, needsLogin: "yes" },
    });
  } else {
    if (hideForAuth && auth.currentUser) {
      if (to.query.redirect) {
        next({ path: to.query.redirect as string });
      } else {
        next({
          name: "Dashboard",
          params: { locale: i18n.global.locale.value },
        });
      }
    } else {
      next();
    }
  }
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach(async (to, _from) => {
  await nextTick(() => {
    if (to.meta.title)
      document.title = `Kopiraittilan tekijänoikeusrata  - ${to.meta.title}`;
  });
});

export default router;
