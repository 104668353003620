// Package imports
import { App, createApp } from "vue";
import { firestorePlugin } from "vuefire";
import ElementPlus, { ElNotification } from "element-plus";
import locale from "element-plus/lib/locale/lang/fi";
import "dayjs/locale/fi";
import i18n from "./i18n";

// Local imports
import MainApp from "./App.vue";
import router from "./router";
import { auth } from "@/firebase";
import { store } from "@/store";

// Styles
import "element-plus/lib/theme-chalk/index.css";
import "@/styles/index.scss";
import { MutationTypes } from "./store/mutations/types";

let app: App;
auth.onAuthStateChanged((user) => {
  // Only mount if app is not already mounted.
  if (!app) {
    app = createApp(MainApp);

    app
      .use(router)
      .use(firestorePlugin)
      .use(ElementPlus, { locale })
      .use(i18n)
      .use(store);

    app.config.globalProperties.$notify = ElNotification;

    app.mount("#app");

    if (user) {
      user.getIdTokenResult().then((res) => {
        if (res.claims.role === "admin") {
          store.commit(MutationTypes.SET_IS_ADMIN, true);
        }
      });
    }
  }
});
