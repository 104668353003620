import { createLogger, createStore } from "vuex";
import { Results, User } from "@app/shared";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export interface State {
  users: User.UserInformation[];
  sessions: Record<string, Results.GameStatsObject>;
  user: {
    isAdmin: boolean;
  };
}

// Create a new store instance.
export const defaultState = (): State => {
  return {
    users: [],
    sessions: {},
    user: { isAdmin: false },
  };
};

const state = defaultState();

const store = createStore({
  state,
  mutations: mutations,
  actions: actions,
  getters: getters,
  plugins: process.env.NODE_ENV === "production" ? [] : [createLogger()],
  devtools: process.env.NODE_ENV !== "production",
});

export { store };
