import { State } from "@/store";
import { User } from "@app/shared";

export default {
  getUsers(state: State): User.UserInformation[] {
    return state.users;
  },
  getPlayersFromSession:
    (state: State) =>
    (gameCode: string): Record<string, unknown> => {
      return state.sessions[gameCode]?.players ?? {};
    },
  getQuestionsFromSession:
    (state: State) =>
    (gameCode: string, swedish = false): unknown[] => {
      if (!swedish) return state.sessions[gameCode]?.questions ?? [];
      if (
        state.sessions[gameCode]?.questions_sv &&
        state.sessions[gameCode]?.questions_sv.length > 0
      )
        return state.sessions[gameCode]?.questions_sv ?? [];
      else return state.sessions[gameCode]?.questions ?? [];
    },
  isAdmin: (state: State): boolean => state.user.isAdmin,
};
