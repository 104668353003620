import { MutationTypes } from "@/store/mutations/types";
import { Results, User } from "@app/shared";
import { defaultState, State } from "@/store";

interface GameSessionPayload {
  gameCode: string;
  data: Results.GameStatsObject;
}

export default {
  [MutationTypes.SET_USERS](
    state: State,
    payload: User.UserInformation[]
  ): void {
    state.users = payload;
  },
  [MutationTypes.SET_GAME_SESSION](
    state: State,
    payload: GameSessionPayload
  ): void {
    state.sessions[payload.gameCode] = payload.data;
  },
  [MutationTypes.SET_IS_ADMIN](state: State, payload: boolean): void {
    state.user.isAdmin = payload;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [MutationTypes.RESET_STORE](state: State, _: void): void {
    Object.assign(state, defaultState());
  },
};
