
import { defineComponent } from "vue";
import LoginForm from "@/components/LoginForm.vue";
import { ElMessage } from "element-plus";
import { auth } from "@/firebase";
import firebase from "firebase";
import { User } from "@app/shared";
import { MutationTypes } from "@/store/mutations/types";
import { mapMutations } from "vuex";
import UserCredential = firebase.auth.UserCredential;

export default defineComponent({
  name: "Login",
  components: {
    LoginForm,
  },
  created() {
    if (this.$route.query.needsLogin) {
      ElMessage({
        type: "warning",
        message: this.$t("login.login_to_access"),
      });
    }
  },
  methods: {
    ...mapMutations({
      setAdminState: MutationTypes.SET_IS_ADMIN,
    }),
    async loginUser(userCredentials: UserCredential) {
      // We get the custom claims from the idToken
      const idTokenResult = await userCredentials.user?.getIdTokenResult();

      // Setup the roles allowed to login.
      const allowedRoles: User.RoleArray = ["admin", "teacher"];

      // If the logged in user is in the allowed role we log in
      if (
        idTokenResult?.claims.role &&
        allowedRoles.includes(idTokenResult.claims.role)
      ) {
        this.setAdminState(idTokenResult.claims.role === "admin");
        await this.$router.push({
          path:
            (this.$route.query.redirect as string) ??
            `/${this.$i18n.locale}/dashboard/games`,
        });
      } else {
        await auth.signOut();
        this.showErrorNotification(this.$t("login.error.not_allowed"));
      }
    },
    showErrorNotification(message: string) {
      ElMessage({
        type: "error",
        message,
      });
    },
  },
});
