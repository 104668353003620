
import { defineComponent } from "vue";
import { toFormValidator } from "@vee-validate/zod";
import { z } from "zod";
import { useField, useForm } from "vee-validate";
import { auth } from "@/firebase";
import { useI18n } from "vue-i18n";

interface LoginFormValues {
  email?: string;
  password?: string;
}

/**
 * This component shows a login card component.
 */
export default defineComponent({
  name: "LoginForm",
  emits: ["loginError", "roleError", "loginSuccess"],
  setup(props, context) {
    // Grab emit from context and get a router object.
    const { emit } = context;

    const { t } = useI18n();

    // Zod schema validation.
    const validationSchema = toFormValidator(
      z.object({
        email: z
          .string()
          .nonempty(t("login.email.error.empty"))
          .email({ message: t("login.email.error.wrong") }),
        password: z
          .string()
          .nonempty(t("login.password.error.empty"))
          .min(6, { message: t("login.password.error.wrong") }),
      })
    );

    const { handleSubmit, errors, resetForm, isSubmitting } = useForm({
      validationSchema,
    });

    const { value: email } = useField("email");
    const { value: password } = useField("password");

    const onSubmit = handleSubmit(async (values: LoginFormValues) => {
      const { email, password } = values;

      // If the values are somehow undefined we return.
      if (!email || !password) return;

      try {
        // Login using email/password
        const userCredentials = await auth.signInWithEmailAndPassword(
          email,
          password
        );

        resetForm();

        emit("loginSuccess", userCredentials);
      } catch (e) {
        console.error(e);
        emit("loginError");
      }
    });

    return {
      validationSchema,
      email,
      password,
      errors,
      onSubmit,
      isSubmitting,
    };
  },
  methods: {
    forgotPassword: async function () {
      await this.$router.push({
        name: "ResetPassword",
        params: { locale: this.$i18n.locale },
      });
    },
    createAccount: async function () {
      await this.$router.push({
        name: "Register",
        params: { locale: this.$i18n.locale },
      });
    },
  },
});
