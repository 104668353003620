import { ActionTypes } from "@/store/actions/types";
import { ActionContext } from "vuex";
import { State } from "@/store";
import { getSessionStats, listUsers } from "@/firebase";
import { MutationTypes } from "@/store/mutations/types";

interface FetchStatsPayload {
  force: boolean;
  gameCode: string;
}

export default {
  async [ActionTypes.FETCH_USERS](
    { commit, state }: ActionContext<State, unknown>,
    payload = false
  ): Promise<boolean> {
    // We check if this is not a forceful fetch and we have users.
    if (!payload && state.users.length > 0) {
      return true;
    }
    try {
      const result = await listUsers();
      const { data } = result;
      if (data.error) {
        return false;
      }
      commit(MutationTypes.SET_USERS, data.data);
    } catch (e) {
      console.error(e);
      return false;
    }
    return true;
  },
  async [ActionTypes.FETCH_STATS](
    { commit, state }: ActionContext<State, unknown>,
    payload: FetchStatsPayload
  ): Promise<boolean> {
    // We check if this is not a forceful fetch and we have users.
    if (!payload.force && state.sessions[payload.gameCode]) {
      return true;
    }
    try {
      const result = await getSessionStats({ gameCode: payload.gameCode });
      const { data } = result;
      if (data.error) {
        return false;
      }
      commit(MutationTypes.SET_GAME_SESSION, {
        gameCode: payload.gameCode,
        data: data.data,
      });
    } catch (e) {
      console.error(e);
      return false;
    }
    return true;
  },
};
