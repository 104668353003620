export default {
  "switch_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda kieli:"])},
  "general": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiraittilan tekijänoikeusrata"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luomalla käyttäjätilin pääset tekemään omia pelejä ja ratoja Kopiraittilan Tekijänoikeusrata-mobiilipeliin. Samalla käyttäjätunnuksella pääset kirjautumaan hallintapaneeliin, jossa voit tarkastella luomiesi pelien tuloksia ja hallinnoida tekemiäsi pelejä."])},
    "privacypolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietosuojaseloste"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyllä"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaisin"])},
    "roles": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ylläpitäjä"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opettaja"])},
      "no_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei roolia"])}
    },
    "messages": {
      "error": {
        "something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtui virhe"])}
      }
    },
    "actions": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu ulos"])}
    },
    "tooltips": {
      "menu": {
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaa valikko"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulje valikko"])}
      }
    }
  },
  "register": {
    "backend": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjätilin lisääminen epäonnistui."])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo käyttäjätunnus"])},
    "name": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
      "error": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi on pakollinen"])},
        "wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "error": {
      "weak_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liian heikko salasana"])},
      "user_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjä on jo olemassa"])}
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo tunnus"])},
    "to_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaisin"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään"])},
    "email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])},
      "error": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti on pakollinen"])},
        "wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti on väärin muotoiltu"])}
      }
    },
    "password": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])},
      "error": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana on pakollinen"])},
        "wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liian lyhyt"])}
      }
    },
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unohditko salasanasi?"])},
    "reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo uusi salasana"])},
    "back_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaisin kirjautumiseen"])},
    "login_to_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinun on kirjauduttava sisään nähdäksesi tämän sivun"])},
    "error": {
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostiosoite tai salasana on virheellinen. Yritä uudelleen."])},
      "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjautuminen ei ole sallittua."])}
    },
    "reset": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohjeet salasanan vaihtamiseen on lähetetty sähköpostiisi. Mikäli et saa linkkiä sähköpostiisi, muista tarkistaa ettei viesti ole joutunut roskapostikansioon."])}
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu"])}
  },
  "menu": {
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät"])},
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelit"])},
    "mygames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omat pelit"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radat"])},
    "mylevels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omat radat"])}
  },
  "users": {
    "table": {
      "headers": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
        "in_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytössä"])},
        "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooli"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae sähköposti"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])}
      },
      "actions": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää käyttäjä"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokaa"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
        "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä salasanan nollaus"])}
      }
    },
    "messages": {
      "error": {
        "removing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe käyttäjän poistamisessa"])}
      },
      "success": {
        "removing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjä poistettu"])}
      },
      "alerts": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistaa tämän käyttäjän?"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko lähettää salasanan palautusviestin käyttäjälle?"])}
      }
    },
    "modal": {
      "create": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää käyttäjä"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjän luominen onnistui!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjän luominen epäonnistui! Käyttäjä voi olla jo olemassa."])}
      },
      "update": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä käyttäjä"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjä päivitetty"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe käyttäjän päivittämisessä"])}
      }
    }
  },
  "levels": {
    "track": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata"])},
      "table": {
        "headers": {
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koodi"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyypi"])}
        }
      },
      "actions": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä tilastot"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])}
      },
      "alerts": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistaa tämän pelin?"])}
      }
    },
    "table": {
      "headers": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyypi"])},
        "set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysymyssarja"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luotu"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelikoodi"])},
        "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luokka-aste"])},
        "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekijä"])},
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sijainti"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae tekijä"])}
      },
      "actions": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä pelit"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])}
      },
      "alerts": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistaa tämän radan?"])}
      },
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei tietoja"])}
    },
    "errors": {
      "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radan poistaminen epäonnistui."])},
      "deletion_cause_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rataa ei voida poistaa koska siihen on liitetty pelejä."])}
    },
    "success": {
      "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radan poistaminen onnistui."])}
    }
  },
  "game": {
    "table": {
      "headers": {
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae pelit"])}
      }
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peli"])},
    "players": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulokset"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae pelaaja"])},
      "tabs": {
        "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteenveto"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelaajat"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisätietoja"])}
      },
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysymys"])},
      "table": {
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
          "answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastauskerrat"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loppuaika"])},
          "puzzle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palapeli"])}
        }
      },
      "average_time": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskimääräinen aika"])}
      }
    },
    "questions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysymykset"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosenttiluku kertoo kuinka suuri osa pelaajista vastasi kysmykseen oikein."])}
    },
    "errors": {
      "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelin poistaminen epäonnistui."])}
    },
    "success": {
      "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelin poistaminen onnistui."])}
    }
  }
}